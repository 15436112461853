import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { AuthLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import Button from '../components/Button';
import * as Auth from '../utils/auth';
import bg from '../images/auth/bg-login.svg';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 712px;
  height: calc(100vh - 142px);
  background: url(${bg}) no-repeat;
  background-size: contain;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  padding-bottom: 60px;
  @media (max-width: 774px) {
    padding: 24px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content;
  gap: 12px;
  margin-bottom: 56px;
  justify-items: center;
`;
const SubTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #7c7f88;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: 432px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
const Title = styled.h1`
  margin: 0;
  font-size: 2.625rem;
  font-weight: normal;

  @media (max-width: 600px) {
    font-size: 1.75rem;
  }

  @media (max-width: 432px) {
    font-size: 1.5rem;
  }
`;

const LoginButton = styled(Button)`
  padding: 10px 60px;
  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 10px 30px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  width: calc(84vw);

  ${LoginButton} + ${LoginButton} {
    margin-top: 20px;
  }
`;

const google = <Icon type="Google" />;

/**
 * @return {null}
 */
function Login() {
  const [authChecking, setAuthChecking] = useState(true);

  useEffect(() => {
    Auth.isLogged().then(isLogged => {
      setAuthChecking(isLogged);
      if (Auth.isStudent() && window) {
        window.location.href = '/app/lms/my-courses';
        return;
      }
      if (isLogged) {
        navigate('/app/lms');
      }
    });
  }, []);

  if (authChecking) {
    return null;
  }

  return (
    <AuthLayout>
      <SEO title="DOL Online Login" keywords={['Login', 'DOL English', 'DOL Online']} />
      <MainContainer>
        <Wrapper>
          <Title>
            Welcome to <b>DOLENGLISH</b>
          </Title>
          <SubTitle>Vui lòng đăng nhập bằng email bạn đã đăng ký khóa học tại DOLENGLISH</SubTitle>
        </Wrapper>

        <Actions>
          <LoginButton size="large" icon={google} onClick={() => Auth.login('google-oauth2')}>
            Log in with Google
          </LoginButton>
        </Actions>
      </MainContainer>
    </AuthLayout>
  );
}

export default Login;
